@import 'reset';


.app {
    height: 100%;
}

.content-page {
}

.page-wrapper {

}