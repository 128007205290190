

.error {
    margin-left: 23px;
    text-align: start;
    color: red;
    font-weight: 500;
    font-size: 13px;
}

.inputError {
    border: 1px solid red;
}